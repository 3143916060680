import { MaskFormatter } from "./maskformatter";

class InstagramFormatter extends MaskFormatter {
    unmask(maskedText: string): string {
        return maskedText.replace("@", "").toLowerCase();
    }

    mask(unmaskedText: string, includeAt?: boolean): string | null {
        if(unmaskedText === "") {
            return unmaskedText;
        }

        let match;

        match = unmaskedText.match(/^([a-z0-9\._]+)$/);
        if(match !== null && match.length !== 0) {
            return `${!!includeAt ? "@" : ""}${unmaskedText}`;
        }

        return null;
    }
}

export { InstagramFormatter };