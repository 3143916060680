import { AbstractApi } from "./api";

class PassifyApi extends AbstractApi {
    private static _instance: PassifyApi;
    protected _host = "https://api.passify.co";

    private constructor() {
        super();
    }

    static get instance() { return this._instance || (this._instance = new this()); }

    // Lead

    register = async (source: string, data: {name?: string, email?: string, instagram?: string, phone?: string}) => {
        return await this._post("/lead", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "source": source,
                ...data,
            })
        });
    }
}

const passifyApi = PassifyApi.instance;

export { passifyApi as PassifyApi };