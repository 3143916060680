import { useNavigate } from "react-router-dom";
import "./landingpage.scss";

const LandingPage = () => {
    const navigate = useNavigate()

    const openStories = () => {
        navigate("/stories");
    }

    return (
        <main id="app-landingpage">
            <div id="top-strip" className="quentinha-strip">
                <pre>@quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   </pre>
            </div>
            <header id="app-header">
                <img id="baladinho" src="/images/baladinho.svg" />
                <img id="logo-quentinha" src="/images/logo-quentinha.png" />
            </header>
            <div id="text">
                <p>
                    Milton Chuquer<br />
                    Seed Selector<br />
                    Duda<br />
                    Avá Araujo
                </p>
                <p>
                    26/08 no @naclubsp às 23h<br />
                    Av São Luis, 282 - República, SP 
                </p>
            </div>
            <div id="app-body-cta" className="cta">
                <button onClick={openStories}>Saber mais 🪩</button>
            </div>
            <div id="bottom-strip" className="quentinha-strip">
                <pre>@quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   @quentinha_afesta   </pre>
            </div>
        </main>
    );
}

export default LandingPage;