import "./music.scss";

const MusicStory = () => {
    return (
        <main id="story-music">
            <img id="story-music-image" src="/images/music.svg" />
            <div id="story-music-text">Completando <b>um ano de pistas</b> em Agosto de 2023, a Quentinha chega para a <b>sexta edição</b> com a promessa de <b>esquentar a noite</b> e <b>ferver a pista</b> com ritmos quentes, tracks dançantes e um público que se entrega até o amanhecer.</div>
            {false && <footer>
                <a href="https://passify.co" target="_blank"><img src="https://passify.co/passify-dark.svg" /></a>
            </footer>}
        </main>
    );
}

export default MusicStory;