import Stories from "./components/stories";
import Invite from "./stories/invite";
import LineUpStory from "./stories/lineup";
import MusicStory from "./stories/music";
import "./story.scss";

const Story = () => {
    return (
        <>
            <Stories items={[
                <MusicStory />,
                <LineUpStory
                    backgroundImage="/images/milton-chuquer-bg.png"
                    djNameImage={{
                        url: "/images/milton-chuquer.svg",
                        positionTop: "273px",
                    }}
                    description="DJ e colecionador de discos em atividade desde 1996 e um dos ícones do House Music nacional, mistura hits e gemas do house na pista da Quentinha"
                    instagramHandler="mchuquer"
                />,
                <LineUpStory
                    backgroundImage="/images/duda-bg.png"
                    djNameImage={{
                        url: "/images/duda.svg",
                        positionTop: "301.15px",
                    }}
                    description="O set do Duda, só quem já viveu sabe:  tracks preciosas do House, com uma energia contagiante pra levar a noite até as últimas consequências."
                    instagramHandler="dudapiratininga"
                />,
                <LineUpStory
                    backgroundImage="/images/seed-selector-bg.png"
                    djNameImage={{
                        url: "/images/seed-selector.svg",
                        positionTop: "278.87px",
                    }}
                    description="DJ e produtor desde 2019, Seed explora texturas melódicas e progressivas do House, adicionando referências do Breakbeat, Funk e até do Miami Bass."
                    instagramHandler="seedselector.wav"
                />,
                <LineUpStory
                    backgroundImage="/images/ava-bg.png"
                    djNameImage={{
                        url: "/images/ava.svg",
                        positionTop: "342px",
                    }}
                    description="Prata da casa, Avá apresenta sets bem humorados e ousados, misturando tudo o que não te deixa ficar parado - até alguns mash-ups que desafiam o limite do bom gosto."
                    instagramHandler="avaaraujo"
                />,
                <Invite />
            ]} duration={5000} />
        </>
    );
}

export default Story;