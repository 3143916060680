import React from "react";
import StoriesTimer from "./timer";
import "./style.scss";

interface StoriesProps {
    items: Array<React.ReactNode>,
    duration: number,
}

const Stories = (props: StoriesProps) => {
    const [current, setCurrent] = React.useState<number>(0);

    const moveBack = () => {
        if(current > 0) {
            setCurrent(oldCurrent => oldCurrent - 1);
        }
    }

    const moveNext = () => {
        if(current < props.items.length - 1) {
            setCurrent(oldCurrent => oldCurrent + 1);
        }
    }

    const storyClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const relX = e.clientX / e.currentTarget.clientWidth;
        const relY = e.clientY / e.currentTarget.clientHeight;
        if(relX < 0.25) {
            moveBack();
        } else if(relX > 0.75) {
            moveNext();
        }
    }

    return (
        <div className="stories" onClick={storyClick}>
            <React.Fragment key={current}>
                <StoriesTimer current={current} length={props.items.length} duration={props.duration} onNext={moveNext} />
                {props.items[current]}
            </React.Fragment>
            {(current < props.items.length - 1) && <div id="stories-move-next-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <rect x="40" y="40" width="40" height="40" rx="13" transform="rotate(-180 40 40)" fill="#F1F3F2" fillOpacity="0.5"/>
                    <path d="M15.6276 28.6328C15.7437 28.7492 15.8816 28.8415 16.0335 28.9045C16.1853 28.9676 16.3481 29 16.5126 29C16.677 29 16.8398 28.9676 16.9916 28.9045C17.1435 28.8415 17.2814 28.7492 17.3976 28.6328L25.7076 20.3228C25.8003 20.2303 25.8738 20.1204 25.924 19.9994C25.9742 19.8784 26 19.7487 26 19.6178C26 19.4868 25.9742 19.3571 25.924 19.2361C25.8738 19.1152 25.8003 19.0053 25.7076 18.9128L17.3976 10.6028C16.9076 10.1128 16.1176 10.1128 15.6276 10.6028C15.1376 11.0928 15.1376 11.8828 15.6276 12.3728L22.8676 19.6228L15.6176 26.8728C15.1376 27.3528 15.1376 28.1528 15.6276 28.6328Z" fill="white" stroke="white" strokeWidth="0.2"/>
                </svg>
            </div>}
        </div>
    );
}

export default Stories;