import "./lineup.scss";

interface DjNameImage {
    url: string,
    positionTop: string,
}

interface LineUpStoryProps {
    backgroundImage: string,
    djNameImage: DjNameImage,
    description: string,
    instagramHandler: string,
}

const LineUpStory = (props: LineUpStoryProps) => {
    return (
        <main id="story-lineup">
            <div id="story-lineup-dj" style={{"--dj-background-image": `url(${props.backgroundImage})`} as React.CSSProperties}>
                <div id="story-lineup-background"></div>
                <div id="story-lineup-overlay"></div>
                <img src={props.djNameImage.url} style={{top: props.djNameImage.positionTop}} />
            </div>
            <div id="story-lineup-description">{props.description}</div>
            <div id="story-lineup-instagram">
                <a href={`https://www.instagram.com/${props.instagramHandler}`} target="_blank"><img src="images/instagram.svg" /></a>
                <a href={`https://www.instagram.com/${props.instagramHandler}`} target="_blank">@{props.instagramHandler}</a>
            </div>
        </main>
    );
}

export default LineUpStory;