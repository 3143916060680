import "./invite.scss";

const Invite = () => {
    const buyTickets = () => {
        window.open("https://m.passify.co/event/quentinha-6", "_blank");
    }

    return (
        <main id="story-invite">
            <iframe src="https://open.spotify.com/embed/playlist/6EnGOQYWawk1keepR8RWFj?utm_source=generator&theme=0" allowFullScreen={false} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />
            <div id="story-invite-text">
                <p>
                    <b>O clima perfeito pro esQuentinha™</b><br />
                    Escute a nossa playlist no Spotify e já vai aquecendo para a festa.
                </p>
                <p>
                    <b>Ainda não tem ingresso?</b><br />
                    No botão abaixo, você <b>encontra o lote disponível e garante presença na pista</b> mais Quentinha de São Paulo.
                </p>
            </div>
            <div id="story-invite-social">
                <a href="https://www.instagram.com/quentinha_afesta/" target="_blank"><img src="/images/instagram.svg" width={24} height={24} alt="" /></a>
                <a href="https://open.spotify.com/playlist/6EnGOQYWawk1keepR8RWFj?si=09ea79cb2c624cd8" target="_blank"><img src="/images/spotify.svg" width={24} height={24} alt="" /></a>
                <a href="https://quentinha.picflow.com/" target="_blank"><img src="/images/pics.svg" width={28} height={24} alt="" /></a>
            </div>
            <div id="story-invite-cta" className="cta">
                <button onClick={buyTickets}>Comprar ingresso 🥵</button>
            </div>
            <footer>
                <a href="https://passify.co" target="_blank"><img src="https://passify.co/passify-dark.svg" /></a>
            </footer>
        </main>
    );
}

export default Invite;