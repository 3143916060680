import React from 'react';
import { InstagramFormatter, PassifyApi, PhoneFormatter } from './shared';
import './leadcapture.scss';

const LeadCapture = () => {
	const [wantToJoin, setWantToJoin] = React.useState<boolean>(false);
	const [success, setSuccess] = React.useState<boolean>(false);
	
	const [phone, setPhone] = React.useState<string>("");
	const [phoneCursor, setPhoneCursor] = React.useState<number>(0);
	const phoneRef = React.useRef<HTMLInputElement>(null);
	const phoneFormatter = new PhoneFormatter();

	const [instagram, setInstagram] = React.useState<string>("");
	const [instagramCursor, setInstagramCursor] = React.useState<number>(0);
	const instagramRef = React.useRef<HTMLInputElement>(null);
	const instagramFormatter = new InstagramFormatter();

	const changePhoneHandle = (e: React.FormEvent<HTMLInputElement>) => {
		const updatedValue = phoneFormatter.updateValue({text: phone, cursor: phoneCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
		setPhone(updatedValue.text);
		setPhoneCursor(updatedValue.cursor);
		e.preventDefault();
	}

	React.useEffect(() => {
		const input = phoneRef.current;
		if(!!input) {
			input.setSelectionRange(phoneCursor, phoneCursor);
		}
	}, [phoneRef, phoneCursor, phone]);

	const changeInstagramHandle = (e: React.FormEvent<HTMLInputElement>) => {
		const updatedValue = instagramFormatter.updateValue({text: instagram, cursor: instagramCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
		setInstagram(updatedValue.text);
		setInstagramCursor(updatedValue.cursor);
		e.preventDefault();
	}

	React.useEffect(() => {
		const input = instagramRef.current;
		if(!!input) {
			input.setSelectionRange(instagramCursor, instagramCursor);
		}
	}, [instagramRef, instagramCursor, instagram]);

	const requirementsFullfilled = phoneFormatter.unmask(phone).length >= 10 && instagram.length > 0;

	const changedMyMind = (e: React.MouseEvent<HTMLButtonElement>) => {
		setWantToJoin(false);
		e.preventDefault();
	}

	const iWantToJoin = (e: React.MouseEvent<HTMLButtonElement>) => {
		setWantToJoin(true);
		e.preventDefault();
	}

	const register = (e: React.MouseEvent<HTMLButtonElement>) => {
		const unmaskedPhone = phoneFormatter.unmask(phone);
		const updatePhone = unmaskedPhone[0] !== "+" ? `+55${unmaskedPhone}` : unmaskedPhone;
		PassifyApi.register("quentinha", { phone: updatePhone, instagram }).then(response => {
			if(response.status === 200) {
				response.json().then(responseJson => {
					if(responseJson["value"]) {
						setSuccess(true);
					}
				});
			}
		});
		e.preventDefault();
	}

	return (
		<main id="app-leadcapture">
			<header key={`${wantToJoin.toString()}-header`} id="app-header">
				{wantToJoin ?
					<>
						<div id="app-header-back-btn">
							<button className="btn-back" onClick={changedMyMind}><img src="/images/back.svg" /></button>
						</div>
						<div id="app-header-bg">
							<img src={`/images/bg-2-2x.png`} alt="" />
						</div>
					</> :
					<>
						<div id="app-header-bg">
							<img src={`/images/bg-1-2x.png`} alt="" />
						</div>
						<div id="app-header-logo">
							<img src="/images/logo.svg" alt="Clube Quentinho" />
						</div>
					</>
				}
			</header>
			<div key={`${wantToJoin.toString()}-body`} id="app-body">
				{wantToJoin ? 
					<>
						<h2>REGISTRE-SE</h2>
						<p>O clubinho se comunica em dois canais: pelos melhores amigos do insta (<a href="https://www.instagram.com/quentinha_afesta/" target="_blank">@quentinha_afesta</a>) e pelo whatsapp. Preencha os dados para fazer parte!</p>
						<label htmlFor="phone">
							<div className="input">
								<input ref={phoneRef} id="phone" type="text" disabled={success} placeholder="Celular" value={phone} onChange={changePhoneHandle} />
							</div>
						</label>
						<label htmlFor="instagram">
							<div className="input">
								{!!instagram && <span className="instagram-prefix">@</span>}
								<input ref={instagramRef} id="instagram" type="text" disabled={success} placeholder="Instagram" value={instagram} onChange={changeInstagramHandle} />
							</div>
						</label>
						<div id="app-body-cta" className={`cta ${success ? "success" : !requirementsFullfilled ? "disabled" : ""}`}>
							<button disabled={success || !requirementsFullfilled} onClick={register}>{success ? "Valeu 🧡" : "Entrar para o clube 🪩"}</button>
						</div>
					</> : 
					<>
						<h3>Mais que amigos, Quentinhers.</h3>
						<p>No nosso clubinho, você encontra spoilers da próxima edição, acesso antecipado à ingressos, cupons exclusivos e tudo o que você precisa saber sobre a pista mais Quentinha™ de São Paulo.</p>
						<div id="app-body-cta" className="cta">
							<button onClick={iWantToJoin}>Quero fazer parte 🥵</button>
						</div>
					</>
				}
			</div>
			<footer key={`${wantToJoin.toString()}-footer`} id="app-footer">
				<div id="app-footer-box">
					<div id="app-footer-box-overlay"></div>
					<div id="app-footer-box-content">
						<a href="https://www.instagram.com/quentinha_afesta/" target="_blank"><img src="/images/instagram.svg" width={24} height={24} alt="" /></a>
						<a href="https://open.spotify.com/playlist/6EnGOQYWawk1keepR8RWFj?si=09ea79cb2c624cd8" target="_blank"><img src="/images/spotify.svg" width={24} height={24} alt="" /></a>
						<a href="https://quentinha.picflow.com/" target="_blank"><img src="/images/pics.svg" width={28} height={24} alt="" /></a>
					</div>
				</div>
				<div id="app-footer-passify-logo">
					<a href="https://passify.co"><img src="https://passify.co/passify-dark.svg" /></a>
				</div>
			</footer>
		</main>
	);
}

export default LeadCapture;
