import React from "react";
import "./style.scss";

interface StoriesTimerProps {
    length: number,
    current: number,
    duration: number,
    onNext: () => void | Promise<void>,
}

const StoriesTimer = (props: StoriesTimerProps) => {
    let timer: NodeJS.Timer;

    const [progress, setProgress] = React.useState<number>(0);
    const [paused, setPaused] = React.useState<boolean>(false);

    const resume = () => {
        if(!paused) {
            const now = new Date().getTime() - progress * props.duration;
            timer = setInterval(() => {
                const newProgress = Math.min(1, (new Date().getTime() - now) / props.duration );
                setProgress(newProgress);
                if(newProgress >= 1) {
                    props.onNext();
                    setTimeout(() => { setPaused(true); }, 500);
                }
            }, 50);

            return () => {
                if(!!timer) {
                    clearInterval(timer);
                }
            }
        }
    }

    React.useEffect(resume, [paused]);

    const pause = () => {
        if(!!timer) {
            clearInterval(timer);
        }
        setPaused(true);
    }

    const play = () => {
        setPaused(false);
    }

    return (
        <>
            <div className="stories-gesture-detector" onTouchStart={pause} onTouchEnd={play} onMouseDown={pause} onMouseUp={play}></div>
            <div className={`stories-timer-bars ${paused ? "paused" : ""}`} style={{"--bars-length": props.length} as React.CSSProperties}>
                {Array.from(Array(props.length).keys()).map(index =>
                    <div key={`story-bar-${index}`} className="stories-timer-bar">
                        <div className="stories-timer-bar-progress" style={{width: `${100 * (index > props.current ? 0 : index < props.current ? 1 : progress)}%`}}></div>
                    </div>
                )}
            </div>
        </>
    );
}

export default StoriesTimer;